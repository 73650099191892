import {Component, Input, input, OnInit} from '@angular/core';
import {NgStyle} from "@angular/common";

@Component({
  selector: 'app-preloader',
  templateUrl: './preloader.component.html',
  styleUrls: ['./preloader.component.scss'],
  imports: [
    NgStyle
  ],
  standalone: true
})
export class PreloaderComponent implements OnInit {

  @Input() width = 50;
  @Input() height = 50;
  @Input() border = 4;


  constructor() { }

  ngOnInit() {
  }

}
