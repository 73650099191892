// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.preloader-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 15px;
  align-items: center;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 50px;
  height: 50px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 42px;
  height: 42px;
  margin: 4px;
  border: 4px solid #4C275E;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #4C275E transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}`, "",{"version":3,"sources":["webpack://./src/app/shared-module/ui-general/preloader/preloader.component.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,kBAAA;EACA,aAAA;EACA,mBAAA;AACJ;;AAEA;EACI,qBAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;AACJ;;AACE;EACE,sBAAA;EACA,cAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;EACA,WAAA;EACA,yBAAA;EACA,kBAAA;EACA,8DAAA;EACA,yDAAA;AAEJ;;AAAE;EACE,uBAAA;AAGJ;;AADE;EACE,sBAAA;AAIJ;;AAFE;EACE,uBAAA;AAKJ;;AAHE;EACE;IACE,uBAAA;EAMJ;EAJE;IACE,yBAAA;EAMJ;AACF","sourcesContent":[".preloader-wrapper {\n    width: 100%;\n    height: 100%;\n    display: flex;\n    justify-content: center;\n    text-align: center;\n    padding: 15px;\n    align-items: center;\n}\n\n.lds-ring {\n    display: inline-block;\n    position: relative;\n    width: 50px;\n    height: 50px;\n  }\n  .lds-ring div {\n    box-sizing: border-box;\n    display: block;\n    position: absolute;\n    width: 42px;\n    height: 42px;\n    margin: 4px;\n    border: 4px solid #4C275E;\n    border-radius: 50%;\n    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;\n    border-color:  #4C275E transparent transparent transparent;\n  }\n  .lds-ring div:nth-child(1) {\n    animation-delay: -0.45s;\n  }\n  .lds-ring div:nth-child(2) {\n    animation-delay: -0.3s;\n  }\n  .lds-ring div:nth-child(3) {\n    animation-delay: -0.15s;\n  }\n  @keyframes lds-ring {\n    0% {\n      transform: rotate(0deg);\n    }\n    100% {\n      transform: rotate(360deg);\n    }\n  }\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
