import {inject, Injectable} from '@angular/core';
import { saveAs } from 'file-saver/src/FileSaver';

import { ImageURLPipe } from '../pipes/image-url.pipe';
import {of} from "rxjs";
import {DomSanitizer} from "@angular/platform-browser";

@Injectable({
  providedIn: "root",
})
export class DownloadService {

  sanitizer = inject(DomSanitizer)
  constructor() {}

  saveResponse2File = (response: any) => {
    const type = response.headers.get("Content-Type");

    const blob = new Blob([response.body], { type });

    const filename = this.parseContentDisposition(
      response.headers.get("content-disposition")
    );

    saveAs(blob, filename);
  };

  saveResponse2Img = (response: any) => {
    const type = response.headers.get("Content-Type");
    const blob = new Blob([response.body], { type });
    const imageURL = this.sanitizer.bypassSecurityTrustUrl(
      URL.createObjectURL(blob)
    );
    return of(imageURL);
  };

  generateAndDownloadCSV(data: string, title = "fileCSV") {
    const datetime = new Date().getTime();
    const blob = new Blob([data], { type: "text/csv" });
    saveAs(blob, `${title}_${datetime}.csv`);
  }

  imageInNewTab(url: string, width: string | number, height: string | Number) {
    if (!url) {
      return;
    }

    const imageURLPipe = new ImageURLPipe();
    const newTab = window.open();

    const imageUrl = imageURLPipe.transform(url, width, height);
    newTab.document.body.innerHTML = `<img src="${imageUrl}">`;
    newTab.document.title = "Изображение";
  }

  base64Image(url: string, filename = "wgaze") {
    const a = document.createElement("a");
    a.href = url;
    a.download = `${filename}.png`;
    a.click();
  }

  private parseContentDisposition = (disposition: string) => {
    if (disposition && disposition.indexOf("attachment") !== -1) {
      const matches =
        /(?:.*filename\*|filename)=(?:([^'"]*)''|(["'])|)([^;\n]+)\2?(?:[;\n]|$)/i.exec(
          disposition
        );
      if (matches != null && matches[3]) return decodeURIComponent(matches[3]);
    }
    return "";
  };
}
